.bulletin-board {
    background: white;
    display: flex;
    flex-direction: column;
}

.bulletin-board__column {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding-right: 20px;
    padding-left: 20px;
}

    .bulletin-board__column:first-child .bulletin-board__row:first-child h3 {
        margin-top: 0;
    }

.bulletin-board__row-header-spacing {
    border-bottom: 1px solid lightgrey;
    background: white;
    padding-right: 20px;
    padding-left: 20px;
    padding-top: 5px;
}
.bulletin-board__row-header {
    border-bottom: 1px solid lightgrey;
}

.bulletin-board__row-header h3 {
    font-family: ICARubrik,sans-serif;
    font-size: 20px;
    display: flex;
    align-items: center;
}

.bulletin-board__column:last-child {
    border-right: none;
}

.bulletin-board__placeholder {
    background: grey;
    height: 372px;
    display: none;
}

.bulletin-board p {
    line-height: 1.5em;
    color: #333;
    font-size: 16px;
}

.bulletin-board a {
    color: #333;
}

@media (min-width: 768px) {

    .bulletin-board {
        flex-direction: row;
    }

    .bulletin-board__placeholder {
        display: block;
        min-width: 100px;
    }

    .bulletin-board__column {
        border-right: 1px solid lightgrey;
    }

    .bulletin-board__row:first-child h3 {
        margin-top: 0;
    }
}
