.link-edit,.link-action {
    font-family: IcaRubrik,sans-serif;
    font-size: 1rem;
    padding-left: 0;
    padding-right: 0;
    margin-right: 15px;
    padding: 0;
    color: #666;
}

.link-action {
    color: #171717;
    text-decoration: underline;

}
.link-edit:hover,.link-action:hover {
    text-decoration: underline;
    color: #000;
}
