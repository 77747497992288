
.bg--yellow{
    background-color: yellow;
}
.bg--white {
    background-color: white;
}
.bg--green{
    background-color: #4cae4c;
    color: white;
}

.bg--red{
    background-color: #e13205;
    color : #FFFF;
}
.icon--red {
    color: #e13205;
}

.bg--blue{
    background-color: #286090;
    color: white;
}

.bg--gray{
    background-color: rgb(58, 58, 58);
    color: white;
}
.bg--light-gray {
    background-color: #f7f7f7;
}

