.container {
  align-items: center;
  background: #F6F6F6;
  border: 1px solid #DEDDDC;
  border-radius: 4px;
  display: flex;
  height: 46px;
  margin-right: 8px;
  margin-bottom: 8px;
  position: relative;
  width: fit-content;
}
.container > .textInput {
  background: none;
  border: none;
  box-sizing: border-box;
  margin: 0;
  outline: none;
  padding: 14px 11px;
  width: 148px;
}
.textInput {
  font-family: ICATextNy, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  margin: 0;
  padding: 14px 11px;
}
.minusButton {
  align-items: center;
  background: #FCECE7;
  border: 1px solid #FFFFFF;
  border-radius: 593.75px;
  bottom: 32px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  left: 133px;
  min-width: 19px;
  min-height: 19px;
  position: absolute;
}
.minusIcon {
  height: 14.25px;
  position: absolute;
  width: 14.25px; 
}