.no-items-box {
  align-items: center;
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 6rem 0;
  text-align: center;
}
.no-items-box h2 {
  font-family: ICATextNy, sans-serif;
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.4em;
  margin: 0 0 1.5rem;
}
.no-items-box a {
  background: #e13205;
  border-radius: 100px;
  color: #fff;
  display: block;
  font-size: 1rem;
  padding: 0.75rem 1.5rem;
  text-decoration: none;
}