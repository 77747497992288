.no-style {
    list-style: none;
    padding: 0;
    margin-top: 0;
    margin-bottom: 0;
}


.no-style > li:last-child {
    margin-bottom: 0;
    border:none;
}
.list_number {
    font-weight: bold;
    color: #666;
}
.no-style > li{
    margin-bottom: 1px;
    border:none;
    
}

.no-style > li:last-child > .list-item{
    margin-bottom: 0;
    border:none;
}

.list-item {
    padding: 10px 15px;
    background-color: #fff;
    display: flex;
    flex-direction: row;
    /* border-bottom: 1px solid #ddd; */
    justify-content: space-between;
    align-items: center;
    min-height: 38px;
    max-height: 160px;
    overflow: hidden;
    
}

.list-item--strong{
    font-weight: bold;
}

.list-item__element .checkbox {
    margin-bottom: 0;
}

.list-item:hover {
    background-color: #fef7f3;
}

.list-item--start {
    justify-content: start;
    
}


.list-item--flex-row-mobile {
    flex-direction: row;
}

.list-item--flex-row-mobile .list-item__element:first-child {
    justify-content: flex-start;
}

.list-item__heading {
    margin-bottom: 2px;
}
.list-item__heading:hover {
    background-color: #fff;
}

.list-item__heading--spaced{
    margin-top: 30px;
}
.list-item__element {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width:100%;
    justify-content: center;
    margin-right: 5px;
    font-size: 16px;
    /* margin-bottom: 15px; */
}
.list-item__element-fixed-height {
    min-height: 34px;

}
.list-item__element--s {
    max-width: 100px;
    justify-content: flex-start;
}

.list-item__element--column {
    flex-direction: column;
}

.list-item__element--center {
    justify-content: center;
    align-content: center;
}


.list-item__element--right {
    justify-content: flex-end !important;
    align-content: center;
}

.list-item__element--border {
    border-radius: 5px;
}

.list-item__element--padding {
    padding: 2px 4px;
}

.list-item__img {  
    width: 80px;
    height: 80px;
}


.list-item__element--m {
    min-width: 110px;
}
.list-item__element--l {
    min-width: 200px;
}

.list-item__element--small{
    font-size: 12px;
}

.list-item-fill {
    flex-grow: 1;
    max-width:none !important;
}

@media (min-width: 768px) {
    .list-item {
        flex-direction: row;
        max-height: 160px;
        padding: 10px 15px 10px 15px;
    }

    .list-item__element {
        max-width: 300px;
        justify-content: flex-start;
    }
    .list-item__element--m {
        justify-content: center;
        max-width: 150px;
    }
    .list-item__element--s {
        max-width: 100px;
        justify-content: flex-start;
    }
    .list-item__element--center {
        justify-content: center;
        align-content: center;
    }
  }