.groupHeader {
  align-items: center;
  background: #fff;
  display: flex;
  justify-content: space-between;
  padding: 1.5rem 15px;
}

.groupHeading {
  font-size: 1.5rem;
  margin: 0;
}

.list-item__link {
  color: currentColor;
  text-decoration: none;
}

.list-item__link span {
  font-weight: 400;
}

.category-button {
  align-items: center;
  background-color: #fcece7;
  border-radius: 100px;
  color: #cf2e05;
  display: flex;
  font-size: 0.875rem;
  font-weight: 700;
  padding: 0.75rem 1rem;
  text-decoration: none;
}

.link {
  display: block;
  margin-left: auto;
  margin-top: 2rem;
  text-decoration: none;
  width: fit-content;
}

.link-button {
  align-items: center;
  background-color: var(--redDark);
  border: none;
  border-radius: 100px;
  color: #fff;
  cursor: pointer;
  display: flex;
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 1.25rem;
  padding: 0.5rem 1rem;
}

.arrow {
  height: auto;
  margin-left: 0.25rem;
  width: 1rem;
}

.no-products {
  align-items: center;
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 6rem 0;
  text-align: center;
}

.no-products__heading {
  font-family: ICATextNy, sans-serif;
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.4em;
  margin: 0 0 1.5rem;
}

.no-products__link {
  background: #e13205;
  border-radius: 100px;
  color: #fff;
  display: block;
  font-size: 1rem;
  padding: 0.75rem 1.5rem;
  text-decoration: none;
}

.loading-items {
  align-items: center;
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 6rem 0;
  text-align: center;
}

.category-edit {
  appearance: none;
  background-color: transparent;
  box-shadow: none;
  border: 0;
  cursor: pointer;
  color: currentColor;
  margin-left: 1rem;
  padding: 0;
  text-decoration: underline;
}

.groupName {
  align-items: center;
  display: flex;
}

.category-info-wrapper {
  background-color: #fff;
  padding: 16px;
}

.inactive-category-info {
  background-color: #F4F2F9;
  border: 1px solid #BB9EC7;
  border-radius: 4px;
  display: flex;
}

.info-icon {
  padding-top: 16px;
  padding-left: 16px;
  height: 24px;
  width: 24px;
}

.info-text-wrapper {
  padding: 16px;
}

.info-heading {
  margin: 0;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: #652082;
}

.info-text {
  margin: 0;
  font-size: 14px;
  line-height: 20px;
  color: #652082;
}