.pronto-login {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: center;
  width: 100%;
  max-width: 600px;
}

.store-picker {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  justify-content: center;
}
