.badge {
    background-color: #286090;
    color: #fff;
    padding: 4px 6px;
    text-align: center;
    border-radius: 5px;
}

.badge.expanded {
    display: block;
}

.badge.success {
    background-color: #4cae4c;
}