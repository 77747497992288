.modal {
  font-family: ICATextNy, sans-serif;
  align-items: center;
  background: rgba(0, 0, 0, 0.6);
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 400;
  padding: 20px;
  overflow: hidden;
}
.modalWrapper {
  background-color: #fff;
  border: 1px solid #dedddc;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  width: 928px;
  overflow: hidden;
  max-height: 100%;
  padding: 0 65px;
}
.modalHeader {
  align-items: center;
  border-bottom: 1px solid #f6f6f6;
  display: flex;
  justify-content: space-between;
}
.modalTitle {
  margin-left: 1rem;
}
.modalBody {
  margin-bottom: 1rem;
  padding: 0 65px 2rem;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow-y: auto;
}
.modalFooter {
  margin: 45px 0;
}
.closeButton {
  background: none;
  border: none;
  cursor: pointer;
  margin-right: 1rem;
  padding: 0;
}
.noClose {
  display: none;
}
.confirmButton {
  align-items: center;
  background-color: #e13205;
  border: 1px solid #e13205;
  border-radius: 32px;
  color: #ffff;
  cursor: pointer;
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: 0;
  min-width: 123px;
  padding: 10px 15px 10px 15px;
}
