.hamburgerButton {
    z-index: 2;
    position:relative;
    right: -15px;
    display: block;
}

.hamburgerButton span {
    display: block;
    width: 35px;
    height: 5px;
    background-color: rgb(90, 90, 90);
    margin: 6px 0;
    border-radius: 3px;
}

@media (min-width: 768px) {

    .hamburgerButton {
        display: none;
    }
}