.button {
  appearance: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  min-width: 140px;
  white-space: nowrap;
  background-color: #171717;
  border: 1px solid #171717;
  border-radius: 32px;
  color: #fff;
  padding: 10px 15px;
  position: relative;
  font-family: ICATextNy, sans-serif;
}

.button:hover:not([disabled]) {
  background-color: #000;
}

button:disabled.button {
  opacity: 0.5;
  cursor: not-allowed;
}

.button.expanded {
  width: 100%;
}

.button.narrow {
  min-width: auto;
}

.button.inline {
  display: inline-block;
}

/* Icon */
.button .icon {
  margin-right: 10px;
  height: 1rem;
  width: 1rem;
  fill: #fff;
}
.button.small .icon {
  height: 0.75rem;
  width: 0.75rem;
}
.button.medium .icon {
  height: 1.25rem;
  width: 1.25rem;
}
.button.large .icon {
  height: 1.5rem;
  width: 1.5rem;
}

/* Save button */
.button.save {
  border-color: #e13205;
  background-color: #e13205;
}
.button.save:hover:not([disabled]) {
  background-color: #c82c04;
}

/* Action button */
.button.action {
  border-color: #fcece7;
  background-color: #fcece7;
  color: #cf2e05;
}
.button.action:hover:not([disabled]) {
  background-color: #fae5de;
}
.button.action .icon {
  fill: #cf2e05;
}

/* Info button */
.button.info {
  border-color: var(--redDark);
  background-color: var(--redDark);
  color: #fff;
  padding: 0;
  line-height: 2rem;
}
.button.info:hover:not([disabled]) {
  background-color: var(--redDark);
}

/* Cancel button */
.button.cancel {
  color: #171717;
  border-color: #171717;
  background-color: #ffffff;
}
.button.cancel:hover:not([disabled]) {
  color: #ffffff;
  background-color: rgb(58, 58, 58);
}
.button.cancel .icon {
  fill: #171717;
}
.button.cancel:hover:not([disabled]) .icon {
  fill: #fff;
}

/* Text button */
.button.text {
  color: #171717;
  border-color: transparent;
  background-color: transparent;
  text-decoration: underline;
}
.button.text:hover:not([disabled]) {
  background-color: #f5f5f5;
}
.button.text .icon {
  fill: #171717;
}

/* Transparent button */
.button.transparent {
  color: #171717;
  border-color: transparent;
  background-color: transparent;
  text-decoration: underline;
}
.button.transparent:hover:not([disabled]) {
  background-color: transparent;
}
.button.text .icon {
  fill: #171717;
}

/* Sizes */
.button.default {
  font-size: 0.875rem;
}
.button.small {
  font-size: 0.75rem;
}
.button.medium {
  font-size: 1.25rem;
}
.button.large {
  font-size: 1.5rem;
}

/* Loading */
.button.loading {
  pointer-events: none;
  color: transparent !important;
}
.button.loading:after {
  left: calc(50% - 0.65em);
  top: calc(50% - 0.65rem);
  position: absolute !important;
  animation: spinAround 0.5s infinite linear;
  border: 2px solid #dbdbdb;
  border-radius: 9999px;
  border-right-color: transparent;
  border-top-color: transparent;
  content: '';
  display: block;
  height: 1rem;
  position: relative;
  width: 1rem;
}

@keyframes spinAround {
  0% {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(359deg);
  }
}
