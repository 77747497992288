.selectList select {
    box-sizing: border-box;
    width: 100%;
    background-color: #f6f6f6;
    border: 1px #bdbbb9 solid;
    filter: none;
    border-radius: 4px;
    height: 34px;
    padding-left: 10px;
}

.selectList.error select {
    background-color: #fef7f3 !important;
    border-color: #e3000b !important;
    outline-color: #e3000b;
}