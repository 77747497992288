.button {
  border: none;
  border-radius: 1000px;
  cursor: pointer;
  font-family: 'ICATextNy';
  font-size: 14px;
  font-weight: 700;
  height: 40px;
  padding: 8px 20px;
  width: fit-content;
}

.buttonGroup {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
}

.pink {
  background: #fcece7;
  color: #cf2e05;
}

.red {
  background-color: #e13205;
  color: #fff;
}

.cancel {
  margin-right: 10px;
}

.timeGroup {
  margin-bottom: 15px;
}

.checkbox {
  margin-top: 35px;
  margin-bottom: 90px;
}

.container {
  flex-grow: 1;
  font-family: ICATextNy, sans-serif;
  margin-top: 20px;
  min-height: 430px;
  position: relative;
}

.modalFooter {
  margin: 45px 0;
  padding-bottom: 2rem;
}

.deleteButton {
  align-items: center;
  background: none;
  border: none;
  color: #e3000b;
  cursor: pointer;
  display: flex;
  float: right;
  font-size: 14px;
  padding: 0;
}

.deleteText {
  margin-left: 4px;
  text-decoration: underline;
}

.addButton {
  align-items: center;
  background-color: #e13205;
  border: 1px solid #e13205;
  border-radius: 32px;
  color: #ffff;
  cursor: pointer;
  display: flex;
  font-weight: 700;
  justify-content: center;
  margin-left: auto;
  margin-right: 0;
  margin-top: 40px;
  min-width: 123px;
  padding: 10px 15px 10px 15px;
}

.addButton:disabled {
  background-color: #ededed;
  border: #ededed;
  cursor: default;
  color: #868686;
}

.radioGroupWrapper {
  align-items: center;
  display: flex;
  margin-left: 19px;
}

.nameInput {
  width: 759px;
}

.exceptionalHeading {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
}

.exceptionalDay {
  display: inline;
}

.editExceptionalButton {
  background: none;
  border: none;
  color: var(--redDark);
  cursor: pointer;
  margin: 17px 0px 17px 22px;
  padding: 0;
  text-decoration: underline;
}

.exceptionalLabel {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 8px;
}

.subHeading {
  font-family: ICATextNy, sans-serif;
  font-size: 14px;
  margin-top: 30px;
  margin-bottom: 0px;
}

.toggleHeading {
  margin-bottom: 0;
}

.infoText {
  align-items: center;
  color: #626262;
  display: flex;
  font-family: 'ICATextNy';
  font-size: 12px;
  margin: 0;
}

.newWindowIcon {
  position: relative;
  top: 7px;
}
