.textarea {
    width: 100%;
    height: 80px;
    background-color: #f6f6f6;
    border: 1px #bdbbb9 solid;
    border-radius: 4px;
    box-sizing: border-box;
    margin-bottom: 0;
    filter: none;
    padding: 10px;
}

.error {
    background-color: #fef7f3 !important;
    border: 1px #e3000b solid !important;
    outline-color: #e3000b;
}