.form {
    display: flex;
    flex-direction: column;
    padding: 0;
    background-color: #FFF;
}


.form-section {
    display: flex;
    flex-direction: column;
    margin: 0;
}

.form-section>.input-label {
    font-size: 14px;
    font-weight: 600;
}

.form-row {
    display: flex;
    flex-direction: column;

}

.row {
    display: flex;
    flex-direction: row;
    margin-right: 0;
    margin-left: 0;
}

.row--align-center {
    align-items: center;
}

@media (min-width: 768px) {
    .form-row {
        flex-direction: row;
    }

}