.container {
  align-items: center;
  color: #3a3a3a;
  display: flex;
  font-size: 16px;
  line-height: 24px;
}

.label {
  font-family: 'ICATextNy';
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

.toggleBox {
  display: inline-block;
  height: 22px;
  margin-left: 9px;
  position: relative;
  width: 44px;
}

.toggleBox input {
  height: 0;
  opacity: 0;
  width: 0;
}

.checkIcon {
  height: 16px;
  left: 2px;
  position: absolute;
  top: 2px;
  width: 16px;
}

.slider {
  -webkit-transition: 0.4s;
  background-color: #fff;
  border: 2px solid #bdbbb9;
  border-radius: 10000px;
  bottom: 0;
  cursor: pointer;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: 0.4s;
}

.slider:before {
  -webkit-transition: 0.4s;
  background-color: #fff;
  border-radius: 50%;
  content: '';
  height: 14px;
  position: absolute;
  top: 2px;
  transition: 0.4s;
  width: 14px;
}

input:not(:checked) + .slider:before {
  background-color: #bdbbb9;
  left: 2px;
}

input:checked + .slider {
  background-color: var(--redDark);
  border: 2px solid var(--redDark);
}

input:checked + .slider:before {
  -webkit-transform: translateX(24px);
  -ms-transform: translateX(24px);
  transform: translateX(24px);
}
