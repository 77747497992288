.stats-container-wrapper {
    background: white;
    margin-bottom: 12px;

}

.stats-container-wrapper__header {
    border-bottom: 1px solid lightgrey;
    background: white;
    padding: 20px 20px;
    padding-bottom: 0;
}

.stats-container-wrapper__header h3 {
    margin-top: 0;
    font-family: ICARubrik,sans-serif;
    font-size: 20px;
}

.stats-container-wrapper__body {
    padding-top: 3px;
}



.stats-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-wrap: wrap;
}
.stats-container__item {
    background: white;
    padding: 10px 25px 25px 10px;
    margin-bottom: 10px;
}
.stats-container__item:first-child {
    margin-left: 0;
}

.stats-container__item:last-child {
    margin-right: 0;
}

.stats-container__headline {
    font-family: ICATextNy,sans-serif;
    font-size: 15px;
    margin: 0;
    text-align: center;
}

.stats-container__today_number {
    margin-top: 10px;
    font-size: 50px;
    line-height: 50px;
    font-family: IcaRubrik,sans-serif;
    font-weight: bold;
    text-align: center;
    display: block;
}
.stats-container__today_number_row {
    margin-top: 0;
    font-size: 50px;
    line-height: 5px;
    font-family: IcaRubrik,sans-serif;
    font-weight: bold;
    text-align: center;
    display: block;
}

.stats-container__monthly_report_row {
    margin-top: 0;
    font-size: 12px;
    line-height: 10px;
    font-family: IcaRubrik,sans-serif;
    text-align: center;
    display: block;
}

.stats-container__currentweek_number {
    margin-top: 10px;
    font-size: 20px;
    line-height: 50px;
}
.stats-container__lastweek_Number {
    margin-top: 10px;
    font-size: 20px;
    line-height: 50px;
    font-weight: normal;
}

.campaign-container__values {
    font-size: 15px;
    line-height: 50px;
    font-family: IcaRubrik,sans-serif;
    text-align: center;
    display: block;
}


@media (min-width: 768px) {
    .stats-container {
        display: flex;
        flex-direction: row;
    }

    .stats-container__item {
        width: 21%;
        margin-bottom: 0px;
    }
}