.qrGeneratorWrapper .content {
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 1rem 0;
}
.qrGeneratorWrapper .qrContainer {
  align-items: center;
  background-color: #fff;
  border-radius: 0.25rem;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.12);
  display: inline-flex;
  justify-content: center;
  margin-bottom: 1.5rem;
  min-width: 270px;
  min-height: 270px;
  padding: 1rem;
  position: relative;
}

.qrGeneratorWrapper .qrContainer .tooltip {
  visibility: hidden;
  width: fit-content;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 5px;
  position: absolute;
  bottom: 5px;
  left: 95%;
  z-index: 1;
}

.qrGeneratorWrapper .qrContainer:hover .tooltip {
  visibility: visible;
}

.qrGeneratorWrapper .input,
.qrGeneratorWrapper .errorInput {
  appearance: none;
  background-color: transparent;
  border: 1px solid #79767f;
  border-radius: 0.25rem;
  margin-bottom: 0;
  box-shadow: none;
  font-size: 0.9rem;
  height: 3rem;
  padding: 0 0.75rem;
  text-align: left;
  width: 40%;
}

.qrGeneratorWrapper .errorInput {
  background-color: #ffdad4;
  border-color: 1px solid #ba1b1b;
  max-width: 113px;
  max-height: 40px;
}

.qrGeneratorWrapper .input::-webkit-input-placeholder {
  color: #79767f;
}
.qrGeneratorWrapper .input::-moz-placeholder {
  color: #79767f;
}
.qrGeneratorWrapper .input:-ms-input-placeholder {
  color: #79767f;
}
.qrGeneratorWrapper .input:-moz-placeholder {
  color: #79767f;
}

.qrGeneratorWrapper .errorInput::-webkit-input-placeholder {
  color: #ba1b1b;
}
.qrGeneratorWrapper .errorInput::-moz-placeholder {
  color: #ba1b1b;
}
.qrGeneratorWrapper .errorInput:-ms-input-placeholder {
  color: #ba1b1b;
}
.qrGeneratorWrapper .errorInput:-moz-placeholder {
  color: #ba1b1b;
}
.qrGeneratorWrapper .button,
.qrGeneratorWrapper .copyButton {
  appearance: none;
  background-color: #e13205;
  border: 0;
  border-radius: 0.25rem;
  box-shadow: none;
  color: #fff;
  font-size: 1rem;
  padding: 1rem 2rem;
  width: 335px;
}

.qrGeneratorWrapper .copyButton {
  margin-top: 1rem;
}

.qrGeneratorWrapper .copyButton a,
.qrGeneratorWrapper .copyButton a:visited {
  text-decoration: none;
  color: inherit;
}

.qrGeneratorWrapper .button:active,
.qrGeneratorWrapper .copyButton:active {
  background-color: #b72805;
}

.qrGeneratorWrapper #canvas {
  visibility: hidden;
  display: none;
}

.qrGeneratorWrapper .inputContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
}
