.container {
  align-items: flex-start;
  border-radius: 4px;
  display: flex;
  padding: 8px;
  gap: 12px;
  margin-top: 4px;
  transition: opacity 5s;
  opacity: 1;
}

.info {
  background-color: #f4f2f9;
  border: 1px solid #bb9ec7;
  color: #652082;
}

.warning {
  background-color: #fffceb;
  border: 1px solid #ecb45e;
  color: #e84c05;
}

.error {
  background-color: #fef7f3;
  border: 1px solid #e17366;
  color: #e3000b;
}

.infoIcon {
  height: 20px;
  width: auto;
}

.errorIcon {
  height: 20px;
  width: auto;
}

.title {
  font-family: 'ICATextNy';
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  margin: 0;
}

.message {
  margin: 0;
  font-family: 'ICATextNy';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}

.link {
  color: #cf2e05;
  font-weight: 700;
  text-decoration: none;
}

.link:hover {
  text-decoration: underline;
}
