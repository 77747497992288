.datePickerInput {
    width: 100%;
    height: 34px;
    background-color: #f6f6f6;
    border: 1px #bdbbb9 solid;
    border-radius: 4px;
    box-sizing: border-box;
    margin-bottom: 0;
    filter: none;
    padding-left: 10px;
}