.timePickerWrapper {
    display: flex;
    flex-direction: row;
    gap: 1rem;
}

.validationError {
    display: block;
    color: #e3000b;
    margin-top: -7px;
    margin-bottom: 16px;
    font-size: 12px;
    font-family: ICATextNy, sans-serif;
}