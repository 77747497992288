.item-list {
    width: 100%;
    border-spacing: 0;
    background-color: #fff;
    border-collapse: collapse;
}

.item-list a {
    color: #cf2e05;
    text-decoration:none;
}

.item-list a.item-list-row {
    display: table-row;
}

.item-list td, 
.item-list th {
    padding: 15px;
    text-align: left;
    vertical-align: middle;
}

.item-list td.narrow, 
.item-list th.narrow {
    width:1px;
    white-space:nowrap;
}

.item-list thead th {
    border-bottom: 1px solid #f7f7f7;
    font-weight: bold;
}

.item-list.sticky-header thead th {
    position: sticky;
    top: 0;
    z-index:99;
    background-color:#fff;
}

.item-list tbody td {
    border-top: 1px solid #f7f7f7;
}

.item-list tbody tr:hover td,
.item-list tbody a:hover td {
    background-color: #fef7f3;
}

.item-list .col-1 {
    width: 8.33%;
}

.item-list .col-2 {
    width: 16.66%;
}

.item-list .col-3 {
    width: 25%;
}

.item-list .col-4 {
    width: 33.33%;
}

.item-list .col-5 {
    width: 41.66%;
}

.item-list .col-6 {
    width: 50%;
}

.item-list .col-7 {
    width: 58.33%;
}

.item-list .col-8 {
    width: 66.66%;
}

.item-list .col-9 {
    width: 75%;
}

.item-list .col-10 {
    width: 83.33%;
}

.item-list .col-11 {
    width: 91.67%;
}

.item-list .col-12 {
    width: 100%;
}

.item-list.arrow-list td {
    border-top-color: #ddd;
    cursor: pointer;
}

.item-list.arrow-list a {
    color: #000;
}