.link {
    color: #cf2e05;
}

.link svg {
    margin-right:6px;
}

.link:hover {
    text-decoration: underline;
    color: #000;
}