.radioWrapper {
  align-items: center;
  display: flex;
  gap: 9px;
}

.radioButton {
  -webkit-appearance: none;
  appearance: none;
  border: 2px solid #BDBBB9;
  border-radius: 50%;
  cursor: pointer;
  height: 22px;
  margin: 0;
  width: 22px;
}

.radioButton::after {
  border-radius: 50%;
  content: "";
  display: block;
  height: 14px;
  margin: 2px;
  position: relative;
  right: 10px;
  width: 14px;
}

.radioButton:checked::after {
  background-color: var(--redDark);
}

.radioButton:checked {
  border: 2px solid var(--redDark);
}

.radioButtonLabel {
  margin-right: 1rem;
}