.label-heading {
  font-size: 14px;
  font-weight: 600;
}

.media-upload-heading>.input-field .input-label {
  font-size: 14px;
  font-weight: 600;
}

.logo-image {
  padding: 0 10px 10px 10px;
  width: 100px;
  height: 100px;
}