
.btn {
    height: auto;
    width: 200px;
    color: #FFFFFF;
    background-color: #171717 ;
    padding-right: 60px ;
    padding-left: 60px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 32px;
    margin-bottom: 10px;
    white-space: nowrap;
}

.btn--disabled {
    cursor: not-allowed !important;
    background-color: gray !important;
}
.btn--red,.btn-control__link--red {
    background-color: #e13205;
    color : #FFFF;
    text-decoration: none;
}

.btn--white {
    height: auto;
    background: none;
    border: 1px solid #171717;
    color: #171717 ;
    border-color: #171717;
    border-radius: 32px;
}
.btn--white:hover {
    color: white ;
    background-color: rgb(58, 58, 58);
    border-color: rgb(58, 58, 58); 
}

.btn--black {
    color: white ;
    background-color: #171717;
}
.btn--black:hover {
    color: white ;
    background-color: rgb(8, 20, 46);
}

.btn--inline-flex {
    display: inline-flex !important;
}

.btn--xs {
    width: 100px;
    padding-right: 30px ;
    padding-left: 30px ;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 32px;
}

.btn-control{
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    border-radius: 32px;
    min-width: 140px;
    cursor: pointer;
}

.btn-control__content{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px 15px 10px 15px;
    text-decoration: none;
}
.btn-control--absolut {
    position: absolute;
    right: 8px;
}
.btn-control--absolut__top {
    top: 10px;
}
.btn-control__link {
    cursor: pointer;
    text-decoration: none;
}

.btn--red:hover,.btn-control__link--red:hover,.btn-control__link--red:hover {
    background-color: #c82c04;
    color : #FFFF;
    
}

.btn-control--flex-on-top {
    display: flex;
    flex-direction: row;
    z-index: 50;
}