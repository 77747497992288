
.checkbox {
    margin-top: 0 !important;
    margin-bottom: 15px ;
    position: relative;
    margin-left: 0;
    margin-right: 10px;
}
.checkbod--red{
    color: #ff0000;
}
.checkbox-label {
    margin-left: 25px;
    font-size: 14px;
    font-weight: bold;
}
.checkbox-round {
    width: 1.3em;
    height: 1.3em;
    background-color: transparent;
    border-radius: 15%;
    vertical-align: middle;
    border: 1px #171717 solid;
    -webkit-appearance: none;
    outline: none;
    cursor: pointer;
    margin: 0 !important;
    position: relative;
    z-index: 5;
}



.checkmark {
    display:inline-block;
    width: 22px;
    height:22px;
    -ms-transform: rotate(45deg); /* IE 9 */
    -webkit-transform: rotate(45deg); /* Chrome, Safari, Opera */
    transform: rotate(45deg);
}


.checkmark:before {
    content: '';
    position: absolute;
    width: 2px;
    height: 9px;
    background-color: white;
    left: 11px;
    top: 6px;
}

.checkmark {
    cursor: pointer;
    z-index: 2;
    position: absolute;
    top: -2px;
    left: -3px;
}

@media (min-width: 768px) {
    .checkmark {
        top: -2px;
        left: -3px;
    }
  }

.checkmark:after {
    content: '';
    position: absolute;
    width:3px;
    height:2px;
    background-color:white;
    left:8px;
    top:13px;
}

input[type="checkbox"]:checked + .checkmark:before,
input[type="checkbox"]:checked + .checkmark:after {
    background-color: #171717;
}
input[type="checkbox"]:disabled {
    cursor: not-allowed;
}