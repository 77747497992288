.text-left {
    text-align: left !important;
}

.text-center {
    text-align: center !important;
}

.text-right {
    text-align: right !important;
}

.text-no-wrap {
    white-space: nowrap !important;
}

.whitespace-pre {
    white-space: pre !important;
}
