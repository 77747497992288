
.label, h1,h2,h3,h4,h5{
    font-family: IcaRubrik,sans-serif;
    font-weight: bold;
}
.label {
    font-weight: bold;
}


h1 {
    font-size: 2rem;
}
