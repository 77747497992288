.textbox {
    width: 100%;
    height: 34px;
    background-color: #f6f6f6;
    border: 1px #bdbbb9 solid;
    border-radius: 4px;
    box-sizing: border-box;
    margin-bottom: 0;
    filter: none;
    padding-left: 10px;
}

.textbox:disabled {
    background-color: #EDEDED;
    color: #868686;
}

.error {
    background-color: #fef7f3 !important;
    border: 1px #e3000b solid !important;
    outline-color: #e3000b;
}

.tooltipWrapper {
    align-items: flex-end;
    display: flex;
}

.tooltipIcon {
    height: 1.2rem;
    margin-left: 0.5rem;
    width: auto;
}

.tooltip {
    position: relative;
}

.tooltipText {
    visibility: hidden;
    position: absolute;
    width: 480px;
    background-color: #555;
    color: #fff;
    padding: 0.5rem;
    border-radius: 6px;
    top: -5px;
    left: 125%;

    opacity: 0;
    transition: opacity 0.3s;

    font-size: 0.875rem;
    font-weight: 400;
}

.tooltipText::after {
    content: "";
    position: absolute;
    top: 20%;
    right: 100%;
    margin-top: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent #555 transparent transparent;
}

.tooltip:hover .tooltipText {
    visibility: visible;
    opacity: 1;
}