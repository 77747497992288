@font-face {
    font-family: ICATextNy;
    src: url(/public/ICATextNy-Regular.otf);
}

@font-face {
    font-family: ICATextNy;
    src: url(/public/ICATextNy-Bold.otf);
    font-weight: bold;
}

@font-face {
    font-family: ICARubrik;
    src: url(/public/ICARubrik-Regular.woff2);
}

@font-face {
    font-family: ICARubrik;
    src: url(/public/ICARubrik-Bold.woff2);
    font-weight: bold;
}

body {
    margin-top: 0;
}

#root .pronto-root {
    margin: 0;
}

.pronto-root {
    position: relative;
    background-color: #f7f7f7;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family: ICATextNy, sans-serif;

}


.pronto-sidebar--absolute-positioned .pronto-navbar__nav {
    margin-top: 0;
    z-index: 100;
}

.hidden {
    display: none;
}

.pronto-siderbar {
    flex: 1;
    width: 100%;
}

.pronto-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.pronto-content {
    background-color: #fff;
    padding-right: 15px;
    padding-left: 15px;

}

.pronto-container--spacing {
    padding: 15px;
    background-color: #FFF;
}

.nowrap {
    white-space: nowrap;
}

body {
    font-size: 14px;
}

h1 {
    font-size: 25px;
    font-family: IcaRubrik, sans-serif;
}

.flex {
    display: flex;
}

.flex-column {
    display: flex;
    flex-direction: column;
}

.flex-right {
    display: flex;
    align-self: flex-end;
}

.flex-left {
    display: flex;
    align-self: flex-start;
}

.c-3,
.c-6,
.c-12 {
    width: 100%;
}

.width14 {
    width: 14px;
}

hr {
    width: 100%;
    margin-top: 15px;
    margin-bottom: 15px;
    border: 0;
    border-top: 1px solid #eee;
}

.mr-10 {
    margin-right: 10px;
}

.mr-10 {
    margin-right: 10px;
}

.mt-38 {
    margin-top: 38px;
}

.mt-15 {
    margin-top: 15px;
}

.mt-10 {
    margin-top: 10px;
}

.mt-10-mobile {
    margin-top: 10px;
}

.ml-10 {
    margin-left: 10px;
}

.mb-10 {
    margin-bottom: 10px;
}

.mb-10-mobile {
    margin-bottom: 10px;
}

.pt-0 {
    padding-top: 0;
}

.pr-10 {
    padding-right: 0;
}

.pl-10 {
    padding-left: 0;
}

.pr-10 {
    padding-right: 0;
}

.bg-grey {
    background-color: #f7f7f7;
}

.hidden-down {
    display: none;
}

.layout-relative {
    position: relative;
}

.layout-l-absolut {
    position: initial;
}

.main-headline {
    font-family: ICARubrik;
    font-size: 38px;
    text-align: center;
    margin: 0;
    color: #e13205;
}

.main-byline {
    margin: 30px 30px 30px;
    text-align: center;
}

.status-byline {
    text-align: center;
}

.status-report-table {
    min-width: 800px;
}

.status-report-table li,
.status-report-table .list-item {
    overflow: visible
}

/* Modal  */
.modal {
    position: fixed;
    z-index: 100;
    padding-top: 100px;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0, 0, 0);
    /* Fallback color */
    background-color: rgba(0, 0, 0, 0.4);
    /* Black w/ opacity */
}

/* Modal Content */
.modal__content {
    z-index: 101;
    background-color: #fefefe;
    margin: auto;
    padding: 20px;
    border: 1px solid #888;
    width: 50%;
}

@media (min-width: 768px) {
    .status-report-table {
        min-width: initial;
    }

    .status-report-table .list-item__element {
        width: 9%;
    }

    .status-report-table .list-item {
        flex-wrap: nowrap;
    }

    .outerLayoutContainer {
        margin: 0 50px;
    }

    .pronto-root {
        flex-direction: row;
        align-items: flex-start;
    }

    .layout-l-absolut {
        position: absolute;
    }

    .c-3 {
        width: 25%;
    }

    .c-6 {
        width: 50%;
    }

    .pr-10 {
        padding-right: 10px;
    }

    .pl-10 {
        padding-left: 10px;
    }

    .pr-10 {
        padding-right: 10px;
    }

    .mb-10 {
        margin-bottom: 10px;
    }

    .r-160 {
        right: 160px;
    }

    .r-192 {
        right: 192px;
    }

    .r-335 {
        right: 335px;
    }

    .hidden-up {
        display: none;
    }

    .hidden-down {
        display: flex;
    }

    .mb-10-mobile {
        margin-bottom: 0px;
    }

    .mt-10-mobile {
        margin-top: 0px;
    }
}

.top-container {
    margin-top: 0;
}

.mid-container {
    margin-top: 12px;
}

.gutter {
    padding: 15px;
}

.arrow-list .list-item {
    padding-top: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid #ddd;
    flex-direction: row;
}

.arrow-list .list-item__element {
    justify-content: flex-start;

}

.arrow-list .arrow-list__header {
    background: white;
    padding: 10px 15px;
    border-bottom: 1px solid #ddd;
    height: 44px;
    display: flex;
    align-items: center;
}

.arrow-list__header--space-between {
    justify-content: space-between;
}

.arrow-list .arrow-list__header h3 {
    font-size: 20px;
    margin: 0;
}

.arrow-list__header .arrow-list__header__element {
    font-size: 16px;
    margin: 0;
    width: 100%;
    max-width: 300px;
}

.arrow-list a {
    text-decoration: none;
}

.arrow-list--left-align {
    justify-content: flex-start !important;
}

.drop-shadow {
    box-shadow: 0px 0px 4px #ddd;
}

.pointer-hand {
    cursor: pointer;
}

.time-display {
    min-height: 25px;
}

.status-icon {
    width: 30px;
    height: 30px;
}

.ellipsis_text {
    width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.file-upload {
    visibility: hidden;
    height: 5px;
}

.btn-file-upload {
    padding: 10px;
    font-weight: bold;
}

.image-chooser {
    z-index: 1000;
}