.input-field.checkbox {
    margin-bottom: 11px;
    display: flex;
    align-items: center;
}

.input-field.checkbox label {
    display: inline-flex;
    font-size: 1rem;
    line-height: normal;
}