.container {
    margin-bottom: 2.125rem;
}

.header {
    align-items: center;
    background: white;
    border-bottom: 1px solid #ddd;
    box-sizing: border-box;
    display: flex;
    font-family: ICATextNy, sans-serif;
    height: 4rem;
    justify-content: space-between;
    padding: 0.625rem 1rem;
    width: 100%;
}

.header.test {
    background: var(--testLight);
}

.header.stage {
    background: var(--stageLight);
}

.environmentBanner {
    align-items: center;
    color: white;
    display: flex;
    flex-wrap: nowrap;
    font-family: ICATextNy, sans-serif;
    font-size: 1rem;
    font-weight: 700;
    gap: 2.1rem;
    justify-content: center;
    overflow: hidden;
    padding: 0.75rem;
}

.environmentBanner.local {
    background-color: var(--redDark);
}

.environmentBanner.test {
    background-color: var(--testDark);
}

.environmentBanner.stage {
    background-color: var(--stageDark);
}

.header .logo {
    z-index: 2;
    display: flex;
    align-items: flex-end;
    text-decoration: none;
    gap: 0.25rem;
}

.logoText {
    font-size: 1.875rem;
    color: #E3000B;
    font-weight: 400;
    font-family: IcaRubrik, sans-serif;
    line-height: 2rem;
}

.header .account {
    color: #666;
    display: flex;
    font-size: 1rem;
    z-index: 100;
}

.header .account .username {
    display: none;
    margin-right: 0.625rem;
}

@media (min-width: 768px) {
    .header .account .username {
        display: block;
    }
}