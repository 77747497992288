.imageSelectorContainer {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid lightgray;
  margin-bottom: 1rem;
}

.imageAltContainer {
  display: flex;
  flex-direction: row;
  gap: 2rem;
  width: 100%;
  margin-top: 1rem;
}

.imagePlaceholder,
.imagePreview {
  width: 200px;
  height: 200px;
  color: #3a3a3a;
  font-family: 'ICATextNy';
  background-color: rgba(212, 212, 212, 0.1);
  margin-bottom: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  box-shadow: 0px 0px 1px 0px gray;
}

.imagePreview {
  box-shadow: 0px 0px 1px 0px transparent;
  background-color: transparent;
}

.textBoxContainer {
  display: flex;
  flex-direction: column;
}

.imageHelperText {
  margin-top: '0.5rem';
  color: #626262;
  font-family: 'ICATextNy';
  font-size: '14px';
  font-weight: '400';
}
