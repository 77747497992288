.mediaLibraryUploadInput {
    display: grid;
    grid-template-columns: 1fr min-content;
    gap: 8px;

}

.mediaLibraryUploadInputSelect {
    align-self: end;
}

.mediaLibraryUploadInputSelect>select {
    height: 34px;
    margin-bottom: 16px;
    background-color: #f6f6f6;
    border: 1px #bdbbb9 solid;
    border-radius: 4px;
}