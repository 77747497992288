.wrapper {
  border: 1px solid #bdbbb9;
  border-radius: 0.25rem;
  width: 600px;
}
.datePicker {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}
.calendarHeading {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 1.5rem 4rem;
}
.monthYear {
  display: block;
  flex: 1;
  font-size: 1rem;
  font-weight: 700;
  text-align: center;
}
.weekdays {
  display: flex;
  flex-wrap: wrap;
  padding: 0 2.5rem;
}
.weekdayLabel {
  color: #626262;
  flex: 1;
  font-size: 0.75rem;
  font-weight: 400;
  text-align: center;
  width: calc(100% / 7);
}
.monthSquares {
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  padding: 1.5rem 2.5rem 2.5rem;
}
.emptySquare {
  background: transparent;
  width: calc(100% / 7);
}
.squareButton {
  appearance: none;
  background: #fff;
  border: 0;
  border-radius: 0.25rem;
  box-shadow: none;
  color: #3a3a3a;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 400;
  height: 3.5rem;
  padding: 0;
  width: calc(100% / 7);
}
.dateButton {
  align-items: center;
  background-color: transparent;
  border-radius: 100%;
  display: flex;
  height: 2.5rem;
  justify-content: center;
  margin: 0 auto;
  width: 2.5rem;
}
.squareButtonSelected {
  appearance: none;
  background: #fff;
  border: 0;
  border-radius: 0.25rem;
  box-shadow: none;
  color: #3a3a3a;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 400;
  height: 3.5rem;
  padding: 0;
  width: calc(100% / 7);
}
.squareButtonSelected .dateButton{
  background: #008933;
  color: #fff;
  font-weight: 700;
}
.notSelectableButton{
  appearance: none;
  background: #fff;
  border: 0;
  border-radius: 0.25rem;
  box-shadow: none;
  color: #bdbbb9;
  cursor: default;
  font-size: 1rem;
  font-weight: 400;
  height: 3.5rem;
  padding: 0;
  width: calc(100% / 7);
}
.prevButton,
.nextButton {
  appearance: none;
  background: transparent;
  border: 0;
  box-shadow: none;
  cursor: pointer;
  padding: 0;
}
.next,
.prev {
  align-items: center;
  background-color: #fcece7;
  border-radius: 100%;
  color: #cf2e05;
  display: flex;
  height: 1.5rem;
  justify-content: center;
  width: 1.5rem;
}
.prevButton:disabled .prev {
  background-color: #f6f6f6;
  color: #bdbbb9;
  cursor: default;
}
.prevIcon,
.nextIcon {
  width: 1rem;
}
.hidden {
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  overflow: hidden;
  position: absolute;
  width: 1px;
}

@media (min-width: 60em) {
  .monthYear {
    font-size: 1.25rem;
  }
  .weekdayLabel {
    font-size: 1rem;
  }
}