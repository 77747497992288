.dropdown {
  position: relative;
  max-width: 349px;
}
.dropdownLarge {
  margin-top: 54px;
  max-width: 759px;
}
.dropdownLabel {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  margin: 0;
  padding-bottom: 5px;
}
.selected {
  align-items: center;
  background: #f6f6f6;
  border: 1px solid #bdbbb9;
  border-radius: 4px;
  color: #3a3a3a;
  cursor: pointer;
  display: flex;
  height: 34px;
  justify-content: space-between;
  padding: 0px 10px;
  text-align: left;
  width: 100%;
}
.boldSelected {
  border: 1px solid #dedddc;
  font-family: ICATextNy, sans-serif;
  font-size: 14px;
  font-weight: 700;
  height: 46px;
  padding: 10px 14px;
}

.errorSelected {
  border: 1px solid #e3000b;
}

.openSelected {
  composes: selected;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.closedListIcon {
  align-items: center;
  border-radius: 100%;
  color: #3a3a3a;
  display: flex;
  height: 24px;
  justify-content: center;
  margin-left: 8px;
  transition: transform 0.3s ease-in;
  width: 24px;
}
.red {
  background-color: #e13205;
  color: #fff;
}
.openListIcon {
  composes: closedListIcon;
  transform: rotate(180deg);
}
.angleIcon {
  height: auto;
  width: 1rem;
}

.angleIconBack {
  color: var(--redDark);
  height: auto;
  width: 1.5rem;
}

.openList {
  background-color: #fff;
  border: 1px solid #dedddc;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  list-style-type: none;
  margin: 0;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 99.7%;
  z-index: 6;
}
.listItem,
.listItemHeader {
  background-color: white;
  padding: 11px 14px;
  color: #3a3a3a;
}
.listItem:hover {
  background: rgba(189, 187, 185, 0.5);
}
.listItem > button {
  background: none;
  border: none;
  cursor: pointer;
  font-family: ICATextNy, sans-serif;
  font-size: 14px;
  padding: 0;
  text-align: left;
  width: 100%;
}

.listItem:disabled {
  color: rgba(189, 187, 185, 0.5);
}

.listItemHeader {
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-weight: 800;
  line-height: 1.5rem;
  align-items: center;
  gap: 0.5rem;
}

.checkbox {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
