

.timePickerInput {
    height: 34px;
    background-color: #f6f6f6;
    border: 1px #bdbbb9 solid;
    border-radius: 4px;
    box-sizing: border-box;
    margin-bottom: 0;
    filter: none;
    padding-left: 10px;
}

.timePickerError {
    border: 1px solid #e3000b;
}

.timePickerInput .react-time-picker__wrapper {
    border: none;
}

.timePickerInput .react-time-picker__button svg {
    stroke-width: 1;
}

.timePickerInput .react-time-picker__button:enabled:hover .react-time-picker__button__icon {
    stroke: #e13205;
}