.dragOver {
  background-color: #f7f7f7;
}

.noDragOver {
  background-color: #fff;
}

.dragging {
  background: #fef7f3;
}

.notDragging {
  background: #fff;
}

.dragHandle {
  border-top: 1px solid #f7f7f7;
  text-align: right;
  padding-right: 15px;
  width: 5%;
}