.openingHours {
  display: flex;
  font-family: ICATextNy, sans-serif;
}

.openingInput {
  height: 30px;
  margin-bottom: 0px;
  margin-right: 1rem;
  width: 113px;
}

.inputError {
  border: 1px solid #e3000b !important;
}

.errorText {
  color: #e3000b;
  font-family: IcaTextNy;
  font-size: 14px;
  margin-bottom: 0;
}

.openingTitle {
  line-height: 24px;
  margin-bottom: 0;
  margin-top: 33px;
}

.openingLabel {
  display: block;
  line-height: 24px;
}

.radioGroupWrapper {
  align-items: center;
  display: flex;
  margin-top: 8px;
}

.radioGroupWrapperInput {
  composes: radioGroupWrapper;
  margin-top: 22px;
}

.exceptionalDay {
  display: inline;
}

.editExceptionalButton {
  background: none;
  border: none;
  color: var(--redDark);
  cursor: pointer;
  margin: 17px 0px 17px 22px;
  padding: 0;
  text-decoration: underline;
}

.inputWrapper {
  display: flex;
}