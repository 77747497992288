.groupHeader {
  align-items: center;
  background: #fff;
  display: flex;
  justify-content: space-between;
  padding: 1.5rem 15px;
}
.groupHeading {
  font-size: 1.5rem;
  margin: 0;
}
.list-item__link {
  color: currentColor;
  text-decoration: none;
}
.list-item__link span {
  font-weight: 400;
}
.category-button {
  background-color: #fcece7;
  border-radius: 100px;
  color: #cf2e05;
  font-size: 0.875rem;
  padding: 0.75rem 1rem;
  text-decoration: none;
}
.no-products {
  align-items: center;
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 6rem 0;
  text-align: center;
}
.no-products__heading {
  font-family: ICATextNy, sans-serif;
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.4em;
  margin: 0 0 1.5rem;
}
.no-products__link {
  background: #e13205;
  border-radius: 100px;
  color: #fff;
  display: block;
  font-size: 1rem;
  padding: 0.75rem 1.5rem;
  text-decoration: none;
}
.loading-items {
  align-items: center;
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 6rem 0;
  text-align: center;
}
.category-edit {
  appearance: none;
  background-color: transparent;
  box-shadow: none;
  border: 0;
  cursor: pointer;
  color: currentColor;
  margin-left: 1rem;
  padding: 0;
  text-decoration: underline;
}
.groupName {
  align-items: center;
  display: flex;
}
.editCategoryLink {
}
