.checkboxV2 {
    outline: none;
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    user-select: none;
}

.checkboxV2 input {
    position: absolute;
    left: 0;
    opacity: 0;
    outline: none;
    z-index: -1;
}

.checkboxV2 input[type=checkbox]+.check {
    width: 1.5em;
    height: 1.5em;
    flex-shrink: 0;
    border-radius: 4px;
    border: 2px solid #BDBBB9;
    background: #fff;
    position: relative;
}

.checkboxV2 input[type=checkbox]:checked+.check {
    border: 2px solid var(--redDark);
    background: var(--redDark);
    text-align: center;
}

.checkboxV2 .check-icon {
    height: 100%;
}

.checkboxV2 .checkbox-label {
    margin-left: 0.5rem;
    font-size: 14px;
    font-weight: normal;
}

.checkboxV2.disabled {
    cursor: not-allowed;
}

.checkboxV2.readonly {
    cursor: default;
}

.checkboxV2.disabled input[type=checkbox]+.check {
    opacity: 0.5;
}