.variants {
  border: 1px solid #ededed;
  border-radius: 4px;
  margin-bottom: 3rem;
  padding: 1.5rem 0 0;
}
.variantsHeading {
  margin: 0 0 1.5rem;
  padding: 0 1rem;
}
.variantsRow {
  align-items: center;
  border-top: 1px solid #ededed;
  display: flex;
  justify-content: space-between;
  padding: 1rem 1rem;
}
.variantsRowLabel {
  display: block;
  font-weight: 700;
  line-height: 1.25em;
  margin-bottom: 0.5rem;
}
.variantsRowTag {
  background: #ededed;
  border-radius: 20px;
  display: inline-block;
  font-size: 0.875rem;
  font-weight: 700;
  margin-right: 0.5rem;
  padding: 0.25rem 0.75rem;
}
.variantsActions {
  border-top: 1px solid #ededed;
  padding: 1.5rem 1rem;
}
.variantsAddButton {
  appearance: none;
  background: transparent;
  border: 0;
  box-shadow: 0;
  color: #cf2e05;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 700;
  padding: 0;
}
.variantsAddButton span {
  margin-right: 0.5rem;
}
.variantItemsHeader {
  display: flex;
  flex-wrap: wrap;
  font-weight: 700;
  padding: 0 1rem 0.25rem;
}
.variantItemsName {
  flex: 1 1 32%;
  max-width: 32%;
  width: 100%;
}
.variantItemsPrice,
.variantItemsEan {
  flex: 1 1 18%;
  max-width: 18%;
  width: 100%;
}
.variantItemsActive {
  flex: 1 1 14%;
  max-width: 14%;
  width: 100%;
}
.noVariants {
  padding: 0 1rem 1.5rem;
}
.editButton {
  appearance: none;
  background: #fcece7;
  border: 0;
  border-radius: 100px;
  box-shadow: none;
  color: #cf2e05;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 700;
  margin: 0;
  padding: 0.75rem 1.5rem;
}
.removeButton {
  appearance: none;
  background-color: transparent;
  box-shadow: none;
  border: 0;
  cursor: pointer;
  margin-right: 1.5rem;
  padding: 0;
  text-decoration: underline;
}
