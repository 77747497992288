.container {
  background-color: #fff;
  color: #3a3a3a;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: 'ICATextNy';
  font-size: 14px;
  text-decoration: none;
}
.container:hover {
  background-color: #f6f6f6;
}
.listItemWrapper {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-height: 160px;
  min-height: 38px;
  overflow: hidden;
  padding: 10px 15px;
  width: 100%;
  font-family: ICATextNy, sans-serif;
}
.listItem {
  color: #cf2e05;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  width: 100%;
  padding: 0;
}
.small {
  justify-content: flex-start;
  max-width: 100px;
}
.label {
  font-size: 16px;
  font-weight: 700;
  min-width: fit-content;
}
