.contentContainer .header {
    display: flex;
    background-color: #fff;
    border-bottom: 2px solid red;
}

.contentContainer .header .backButton {
    align-items: center;
    display: flex;
    flex-basis: auto;
    flex-direction: row;
    justify-content: flex-start;
    z-index: 2;
    position: relative;
}

.contentContainer .header .title {
    display: flex;
    justify-content: center;
    position: absolute;
    top: 0;
    height: 28px;
    padding: 15px;
    width: calc(100% - 30px);
}

.contentContainer .header .title h2 {
    margin: 0;
    padding: 0;
    line-height: 28px;
}

.contentContainer .header .actions {
    position: absolute;
    top: 0;
    right: 0;
    height: 38px;
    padding: 10px 15px;
    display: flex;
}

.contentContainer .header .actions > * {
    margin-left: 5px;
}

.contentContainer .header .actions > *:first-child {
    margin-left: 0;
}

.contentContainer .content {
    padding: 15px;
    background-color: #fff;
}

.contentContainer .content.expand {
    padding: 0;
}