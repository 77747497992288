.rnc__notification-item--success .rnc__notification-title,
.rnc__notification-item--success .rnc__notification-message {
  font-family: ICATextNy, sans-serif;
  color: #2f6147;
  font-weight: 700;
}

.rnc__notification-item--danger .rnc__notification-title,
.rnc__notification-item--danger .rnc__notification-message {
  font-family: ICATextNy, sans-serif;
  color: #e3000b;
  font-weight: 700;
}

.rnc__notification-item {
  border-radius: 4px;
}

.rnc__notification-item--success {
  background-color: #f5faf4;
  border: 1px solid #7db66d;
}

.rnc__notification-item--danger {
  background-color: #fef7f3;
  border: 1px solid #e17366;
}

.rnc__notification-timer {
  visibility: hidden;
  height: 0px;
}
