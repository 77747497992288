input,.input {
    filter: none;
    border-radius: 4px;
    margin-bottom: 15px;
    height: 34px;
    padding-left: 10px;
}
.input-select--multi{
    height: 100px;
    width: 100%;
}
.input--small {
    width: 30%;
}
input[type=number],.input-select {
    /* width: 50%; */

}

input[type=text],.input {
    background-color: #f6f6f6;
    border: 1px #bdbbb9 solid;
}

.input-textarea {
    height: 100%;
    margin-bottom: 15px;
    padding-left: 10px;
    padding-top: 10px;
}
.input-text {
    background-color: #f6f6f6 !important;
}

.input-edit {
    background-color: #ffffff !important;

}
.input-disabled{
    pointer-events: none;
}

.input-edit,.input-text {
    border: 1px #bdbbb9 solid;
}

.input-spaced{
    margin-top: 50px;
}
@media (min-width: 768px) {
    input[type=number],.input-select {
        /* width: 50%; */
    }
   

  }