.prontoNavbar {
    align-items: center;
    display: none;
    flex-direction: row;
    font-size: 0.875rem;
    justify-content: space-between;
    left: 0;
    margin-right: 2.125rem;
    padding: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 300;
}

.visible {
    display: flex
}

.nav {
    background: #f7f7f7;
    display: flex;
    flex-direction: column;
    list-style: none;
    margin: 0;
    padding: 0;
    width: 100%;
}

.navItem {
    align-items: center;
    background-color: rgb(90, 90, 90);
    color: white;
    display: flex;
    height: 2.375rem;
    margin-bottom: 1px;
}

.navItem:hover {
    background-color: rgb(59, 59, 59);
    cursor: pointer;
}

.navItem.test {
    background-color: var(--testDark);
}

.navItem.stage {
    background-color: var(--stageDark);
}

.navLink {
    color: #fff;
    flex: 1;
    padding: 0.625rem 0.625rem 0.625rem 1rem;
    text-decoration: none;
}

.navLink:hover {
    color: #fff;
}

.navLink:hover.test {
    background-color: var(--testLight);
    color: #000;
}

.navLink:hover.stage {
    background-color: var(--stageLight);
    color: #000;
}

@media (min-width: 768px) {

    .prontoNavbar {
        display: flex;
        position: static;
        width: auto;
    }

    .nav {
        width: 18.75rem;
    }
}