.container {
  flex-grow: 1;
  margin-top: 50px;
  min-height: 430px;
  position: relative;
}
.menuLabel {
  align-items: center;
  background: #f6f6f6;
  border: 1px solid #dedddc;
  border-radius: 0.25rem;
  color: #3a3a3a;
  cursor: pointer;
  display: flex;
  font-family: ICATextNy, sans-serif;
  font-size: 14px;
  height: 2.875rem;
  justify-content: space-between;
  line-height: 24px;
  padding: 10px 14px;
  text-align: left;
  width: 759px;
}
.storeInfoButtonIcon {
  align-items: center;
  background-color: #e13205;
  border-radius: 100%;
  color: #fff;
  display: flex;
  height: 1.5rem;
  justify-content: center;
  margin-left: 0.5rem;
  transition: transform 0.3s ease-in;
  width: 1.5rem;
}
.activeStoreInfoButtonIcon {
  composes: storeInfoButtonIcon;
  transform: rotate(180deg);
}

.storeMenuActive {
  background-color: #fff;
  border: 1px solid rgb(237, 237, 237);
  border-radius: 0.25rem;
  list-style-type: none;
  margin: 5px 0;
  padding: 1rem 1rem 2rem;
  position: absolute;
  top: 55px;
  width: 759px;
  z-index: 2;
}
.valuesContainer {
  margin-top: 55px;
}
.valuesLabel {
  font-family: 'ICATextNy';
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 5px;
}
.valuesGroup {
  align-items: center;
  display: flex;
}
.valueBoxes {
  display: flex;
  flex-wrap: wrap;
}
.plusButton {
  align-items: center;
  background: #fcece7;
  border: none;
  border-radius: 100%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  margin-left: 10px;
  min-height: 24px;
  min-width: 24px;
}
.plusIcon {
  height: 24px;
  position: absolute;
  width: 24px;
}
.newVariant {
  height: 46px;
  margin-top: 30px;
  max-width: 759px;
}
.newVariant > .newVariantInput {
  align-items: center;
  background: #f6f6f6;
  border: 1px solid #dedddc;
  border-radius: 4px;
  box-sizing: border-box;
  color: #3a3a3a;
  display: flex;
  font-family: ICATextNy, sans-serif;
  font-size: 14px;
  height: 100%;
  justify-content: space-between;
  line-height: 24px;
  margin: 0;
  outline: none;
  padding: 10px 14px;
  text-align: left;
  width: 100%;
}
.errorText {
  color: #e3000b;
  font-size: 14px;
  font-family: ICATextNy, sans-serif;
  margin: 8px 0;
}
.saveButton {
  -moz-appearance: none;
  -webkit-appearance: none;
  -webkit-box-shadow: none;
  appearance: none;
  background: #e13205;
  border: 0;
  border-radius: 100px;
  bottom: 0px;
  box-shadow: none;
  color: #fff;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 700;
  height: 3rem;
  margin-top: 1rem;
  min-width: 8.75rem;
  padding: 0 2rem;
  position: absolute;
  right: 0px;
}
