.cateringForm {
  padding-top: 1rem;
  width: 100%;
}

.connectedDeliveryPeriods {
  margin-bottom: 1rem;
}

.iconAsButton {
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-right: 1rem;
}

.limitedDeliveryPeriodButton {
  margin: 0;
  margin-left: 1rem;
  padding: 0;
}

.currentDeliveryPeriods {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  width: 100%;
  flex-wrap: wrap;
}

.form-item {
  width: 100%;
}

.label {
  cursor: pointer;
  display: block;
  font-family: ICATextNy, sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.4em;
  margin-bottom: 0.25rem;
}

.imgLink {
  font-size: 0.875rem;
  line-height: 1.25rem;
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
}

.imgLink > a {
  color: var(--redDark);
}

.groupLabel {
  cursor: pointer;
  display: block;
  font-family: ICATextNy, sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.4em;
  margin-bottom: 0.25rem;
}

.input {
  box-sizing: border-box;
  width: 100%;
}

.radioGroup {
  border: 1px solid #171717;
  border-radius: 4px;
  display: inline-flex;
  margin-bottom: 1.5rem;
}

.radioInput {
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  overflow: hidden;
  position: absolute !important;
  white-space: nowrap;
  width: 1px;
}

.radioInput:checked + .radioLabel {
  background: #171717;
  border-right: 0;
  color: #fff;
}

.radioLabel {
  border-right: 1px solid #171717;
  cursor: pointer;
  padding: 0.5rem 1rem;
  min-width: 6rem;
  text-align: center;
}

.radioGroup .radioLabel:last-child {
  border-right: 0;
}

.checkboxInput {
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  overflow: hidden;
  position: absolute !important;
  white-space: nowrap;
  width: 1px;
}

.checkboxLabel {
  align-items: center;
  cursor: pointer;
  display: flex;
}

.checkboxLabel:before {
  border: 1px solid #171717;
  border-radius: 4px;
  content: '';
  display: block;
  height: 1.5rem;
  margin-right: 0.5rem;
  width: 1.5rem;
}

.checkboxInput:checked + .checkboxLabel:before,
.categoryFilterButtonSelected .checkboxLabel:before {
  background-image: url('/public/check.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
}

.actions {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  padding-top: 1.5rem;
}

.submitButton {
  appearance: none;
  background: #e13205;
  border: 1px solid #e13205;
  border-radius: 100px;
  box-shadow: none;
  color: #fff;
  cursor: pointer;
  display: block;
  font-size: 1rem;
  font-weight: 700;
  height: 3rem;
  margin-left: 1rem;
  min-width: 8.75rem;
  padding: 0;
}

.cancelButton {
  appearance: none;
  background: transparent;
  border: 1px solid;
  border-radius: 100px;
  box-shadow: none;
  color: #171717;
  cursor: pointer;
  display: block;
  font-size: 1rem;
  height: 3rem;
  min-width: 8.75rem;
  padding: 0;
}

.submitButton:hover,
.cancelButton:hover {
  background: #3a3a3a;
  border-color: #3a3a3a;
  color: #fff;
}

.tox.tox-tinymce {
  border-radius: 4px;
}

.tox .tox-edit-area .tox-edit-area__iframe {
  background: #f6f6f6;
}

.tox .tox-statusbar .tox-statusbar__branding {
  display: none;
}

.texteditor {
  margin-bottom: 1rem;
}

.modalContent {
  background: #fff;
  box-shadow: 0 24px 24px 0 rgba(0, 0, 0, 0.13), 0 0 24px 0 rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  display: block;
  height: auto;
  padding: 1.5rem 3rem 1.5rem 1.5rem;
}

.modalHeading {
  font-size: 1.5rem;
  margin: 0 0 1.5rem;
}

.categoryFilter {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 3rem;
}

.categoryFilterItem {
  flex: 1 1 25%;
  margin-bottom: 1.5rem;
}

.categoryFilterButton {
  appearance: none;
  background: transparent;
  border: 0;
  box-shadow: 0;
  margin-right: 1rem;
  padding: 0;
}

.categoryFilterButtonSelected {
  appearance: none;
  background: transparent;
  border: 0;
  box-shadow: 0;
  margin-right: 1rem;
  padding: 0;
}

.selectedAdditionalCategories {
  margin-bottom: 1.5rem;
}

.additionalCategoriesHeading {
  margin-top: 0;
}

.additionalCategoriesButton {
  margin-bottom: 1.5rem;
}

.additionalCategoriesItem {
  margin-right: 1rem;
}

.modalConfirmButton {
  appearance: none;
  background: #e13205;
  border: 0;
  border-radius: 100px;
  box-shadow: none;
  color: #fff;
  cursor: pointer;
  display: block;
  font-size: 1rem;
  font-weight: 700;
  height: 3rem;
  margin: 1rem auto 0;
  min-width: 8.75rem;
  padding: 0;
}

.removeButton {
  appearance: none;
  background-color: transparent;
  box-shadow: none;
  border: 0;
  cursor: pointer;
  margin-right: 1.5rem;
  padding: 0;
  text-decoration: underline;
}

.save-button {
  appearance: none;
  background: #e13205;
  border: 0;
  border-radius: 100px;
  box-shadow: none;
  color: #fff;
  cursor: pointer;
  display: block;
  font-size: 1rem;
  font-weight: 700;
  height: 3rem;
  margin: 1rem auto 1rem;
  min-width: 8.75rem;
  padding: 0 2rem;
}

.openingHours {
  display: flex;
}

.openingInput {
  margin-right: 1rem;
}

.openingLabel {
  display: block;
  line-height: 1.4em;
  margin-bottom: 0.25rem;
}

.removeCategoryButton {
  appearance: none;
  background: transparent;
  border: 0;
  box-shadow: none;
  margin-right: 1.5rem;
  text-decoration: underline;
  padding: 0;
}

input[type='text'].inputError {
  background-color: #f6f6f6;
  border: 1px #e13205 solid;
  border-radius: 4px;
  box-sizing: border-box;
  filter: none;
  height: 34px;
  margin-bottom: 15px;
  padding-left: 10px;
  width: 100%;
}

.slugExists {
  color: #e13205;
  margin: 0;
}

.changeSlugButton {
  appearance: none;
  background: transparent;
  border: 0;
  box-shadow: none;
  margin-right: 1.5rem;
  text-decoration: underline;
  padding: 0;
}

.slugLabel {
  cursor: pointer;
  display: block;
  font-family: ICATextNy, sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.4em;
  margin-bottom: 0.25rem;
}

.slugField {
  background-color: #ededed;
  border: 1px #bdbbb9 solid;
  border-radius: 4px;
  box-sizing: border-box;
  color: #bdbbb9;
  margin-bottom: 15px;
  padding: 9px 0 9px 10px;
  width: 100%;
}
