.pronto-login-wrapper {
    display: flex;
    justify-content: center;
    padding-top: 50px;
}

.pronto-login-wrapper__main-headline {
    font-family: ICARubrik, sans-serif;
    font-size: 20px;
    text-align: center;
    margin-bottom: 46px;
}

.pronto-login {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-self: center;
    width: 100%;
    max-width: 600px;
}

.pronto-login__otherlogin {
 border-top: 1px solid var(--redDark);
 padding: 10px 0;
 text-align: center;
}

 .pronto-login__otherlogin a {
  width: 100%;
  padding: 10px;
}
