.container {
  flex-grow: 1;
  margin-top: 24px;
  position: relative;
}
.modalConfirmButton {
  appearance: none;
  background: #e13205;
  border: 0;
  border-radius: 100px;
  bottom: 0;
  box-shadow: none;
  color: #fff;
  cursor: pointer;
  display: block;
  font-size: 1rem;
  font-weight: 700;
  height: 3rem;
  margin: 1rem auto 0;
  min-width: 8.75rem;
  padding: 0;
  position: absolute;
  right: 0;
}
.headingText {
  font-family: 'ICATextNy';
  font-weight: 800;
  font-size: 16px;
  line-height: 16px;
  margin: 0 0 8px;
}
.categoryWrapper {
  margin-bottom: 32px;
}
.additionalCategoriesText {
  align-items: center;
  appearance: none;
  background-color: transparent;
  border: 0;
  box-shadow: none;
  color: #cf2e05;
  cursor: pointer;
  display: flex;
  font-family: 'ICATextNy';
  font-size: 16px;
  height: auto;
  line-height: 16px;
  padding: 0;
}
.editAdditionalCategories {
  appearance: none;
  background-color: transparent;
  border: 0;
  box-shadow: none;
  color: #cf2e05;
  cursor: pointer;
  font-size: 14px;
  padding: 0;
  text-decoration: underline;
}

.categoryItem {
  margin-bottom: 1.5rem;
}
