.backButton .body {
    height: 28px;
    background: white;
    padding: 15px;
    border-bottom: 2px solid red;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    text-decoration:none;
}

.backButton.simple .body {
    border: 0;
    background: none;
}

.backButton .body:hover {
    text-decoration:none;
}

.backButton .arrow {
    transform: scaleX(-1);
}

.backButton .label {
    color: #cf2e05;
    font-size: 15px;
    font-weight: bold;
    padding-left:10px;
}

.backButton .label:only-child {
    padding-left:0;
}

.backButton .headline {
    display: none;
}

.backButton .headline h2 {
    margin: 0;
}

@media (min-width: 768px) {
    .backButton .headline {
        margin-top: -58px;
        height: 28px;
        padding: 15px;
        display: flex;
        justify-content: center;
    }
}